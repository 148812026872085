"use client"
import React from "react";
import DarkTheme from "layouts/Dark";
// import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import { graphql } from 'gatsby';
import NavbarLR from "components/NavbarLR/navbar";
import Footer from "components/Footer/footer";
import BlogHeader from "components/Blog-Header/blog-header";
import BlogContent from "components/Blog-Content/blog-content";

const IndexPage = ({ data }) => {
  // let story = data.storyblokEntry
  // story = useStoryblokState(story)

  // const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedHeader, MainContent, navbarRef]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 150) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme mobileappstyle={true}>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <BlogHeader sliderRef={fixedHeader} title="Festival Information" philigram="Info" />
      <div ref={MainContent} className="main-content">

        <BlogContent>

          <h3 className="wow color-font extra-title">What, Where, and When</h3>
          <h6 className="wow"></h6>
          <p class="text-white">
            <b>Name: </b> Little River Music Festival
          </p>
          <p class="text-white">
          <b>Date & Time: </b> February 3, 2024, from 11 AM to 8 PM. Gates open 10am
          </p>
          <p class="text-white">
          <b className="wow extra-title">Location: </b>130 Western Valley Road, Little River
          </p>
          <p class="text-white">
          <b className="wow extra-title">Our Purpose: </b>
            Join us as we celebrate the 10th anniversary of the Jujitsu dojo and raise funds for a noble cause - renovating and rebuilding our community hall. This festival is not just about great music and fun; it's a step towards strengthening our community's foundation.
          </p>

          <h3 className="wow color-font extra-title">Main Attractions</h3>
          <p class="text-white">
            Get ready for a lineup of incredible talents, including the Jordon Luck Band, The Late Starters, Western Valley Road Band, Patients, Adam Hattaway & the Haunters, and Al Park. Apart from electrifying musical performances, enjoy local flavours, and a vibrant atmosphere that defines Little River.
          </p>

          <h3 className="wow color-font extra-title">Festival Highlights</h3>
          <h6 className="wow extra-title">
            Music and Performances
          </h6>
          <p class="text-white">
            Non-stop music on the main stage featuring a mix of genres and local talents.
          </p>
          <h6 className="wow extra-title">
            Food and Amenities
          </h6>
          <p class="text-white">
            Savour local cuisines and refreshments, with an emphasis on community favourites.
          </p>
          <h6>
          Accessibility
          </h6>
          <p className="text-white">
            We believe in an inclusive festival experience. While the venue is a paddock it is wheelchair accessible, and we're working to ensure provisions for all attendees. Parking is available, and although public transport may be limited, we encourage carpooling and community travel solutions.
          </p>

          <h3 className="wow color-font extra-title">
          Stay Informed
          </h3>
          <p className="text-white">
            For the latest updates, ticket information, and more, stay connected through our website and social media channels.
          </p>
          <p className="text-white">
            Join us in making the Little River Music Festival a memorable and meaningful event. We can&apos;t wait to see you there!
          </p>
        </BlogContent>
        </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Little River Music Festival</title>
      {/* <link rel="stylesheet" href="demo.css" /> */}
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`